import React from 'react';

interface CurrencyFormatterProps {
  value: number;
  currency: string;
  suppressCode?: boolean;
  prefix?: string
}

export const formatCurrency = (value: number, code: string, suppressCode = false, prefix = '') =>
  value != null
    ? `${prefix}${value.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} ${suppressCode ? '' : code}`.trim()
    : '';

export const Currency: React.FunctionComponent<CurrencyFormatterProps> = ({value, currency, suppressCode, prefix}) =>
  <span>{formatCurrency(value, currency, suppressCode, prefix)}</span>;
